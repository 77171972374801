import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({ urlVideo }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <div>
            <div className="w-full relative content_video flex justify-center ">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[60px] md:bottom-[100px] lg:bottom-[90px] md:text-start text-center text-white">
                    <div className="w-full text-center md:text-start p-4 relative flex md:flex-row flex-col justify-center items-center">
                        <div>
                            <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{rpdata?.dbSlogan?.[0].slogan}</h1>
                            <p className="md:w-1/2 w-[90%] mx-auto md:mx-0">{rpdata?.dbValues?.[0].description.substring(0, 161)}</p>
                            <ButtonContent btnStyle="three" />
                        </div>

                        <div>
                            <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2F15%20Años.png?alt=media&token=5b349642-3cbf-48cf-b48d-ce505ac0d133" 
                            alt="YearsEXP"
                            className="md:w-[500px] w-[150px] md:-mt-20 mt-5"/>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}
export default HeroVideo;